export const tableColumnsMostVisited = [
  {
    Header: "Page Name",
    accessor: "page name",
  },
  {
    Header: "Visitors",
    accessor: "visitors",
  },
  {
    Header: "Unique Visitors",
    accessor: "unique visitors",
  },
  {
    Header: "Clients",
    accessor: "clients",
  },
  {
    Header: "Bounce Rate",
    accessor: "bounce rate",
  },
];
