export const columnsDataUsersOverview = [
  {
    Header: "USER NAME",
    accessor: "name",
  },
  {
    Header: "EMAIL",
    accessor: "email",
  },
  {
    Header: "USERNAME",
    accessor: "username",
  },
  {
    Header: "JOIN DATE",
    accessor: "date",
  },
  {
    Header: "USER TYPE",
    accessor: "type",
  },
  {
    Header: "ACTIONS",
    accessor: "actions",
  },
];
