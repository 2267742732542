export const calendarData = [
  {
    title: "Meeting with a client",

    borderColor: "transparent",
    start: "2022-09-27",
    end: "2022-09-29",
    backgroundColor: "#7551FF",
    className: "info",
  },

  {
    title: "Webinar - Marketing for Developers",

    borderColor: "transparent",
    start: "2022-10-06",
    end: "2022-10-08",
    backgroundColor: "#01B574",
    className: "success",
  },

  {
    title: "Design UI and check sales on PayPal",

    borderColor: "transparent",
    start: "2022-10-10",
    end: "2022-10-12",
    backgroundColor: "#FFB547",
    className: "warning",
  },

  {
    title: "Configure table for Daily tasks on Notion",

    borderColor: "transparent",
    start: "2022-10-21",
    end: "2022-10-23",
    backgroundColor: "#7551FF",
    className: "error",
  },
];
