export const tableColumnsManagement = [
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Date",
    accessor: "date",
  },
  {
    Header: "Permissions",
    accessor: "permissions",
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "Price",
    accessor: "price",
  },
  {
    Header: " ",
    accessor: "button",
  },
];
